import React from 'react';
import { useRouter } from 'next/router';
import { TimelineMax } from 'gsap';
import NProgress from 'nprogress';

import { useUI } from 'utils/ui';
import { config } from 'utils/config';
import { BrandLogo } from 'components/assets';

import s from './PageTransition.module.scss';

type Props = {
  children: React.ReactNode;
};

export const PageTransition = ({ children }: Props) => {
  const router = useRouter();
  const ui = useUI();
  const logoRef = React.useRef<HTMLDivElement>(null);
  const maskRef = React.useRef<HTMLDivElement>(null);
  const brandRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const show = () => {
      const t = new TimelineMax();
      const duration = config.animations.pageTransition.showDuration / 1000;

      t.set([maskRef.current, brandRef.current, logoRef.current], { display: 'flex' });
      t.addLabel('start');
      t.fromTo(
        maskRef.current,
        duration * 0.75,
        { y: '100%' },
        { y: '0%', ease: 'Power4.easeInOut' },
        `start+=${duration * 0.25}`
      );
      t.fromTo(
        brandRef.current,
        duration,
        { y: '100%' },
        { y: '0%', ease: 'Power4.easeInOut' },
        'start'
      );
      t.fromTo(
        logoRef.current,
        duration / 2,
        { opacity: 0 },
        { opacity: 1 },
        `start+=${duration / 2}`
      );
    };

    if (!maskRef.current || !brandRef.current) {
      return;
    }
    if (ui.isPageTransitioning) {
      show();
    }
  }, [ui.isPageTransitioning]);

  React.useEffect(() => {
    const hide = () => {
      const t = new TimelineMax();
      const duration = config.animations.pageTransition.hideDuration / 1000;

      if (!ui.isPageTransitioning) {
        return;
      }

      t.call(() => ui.toggleNavigation(false));
      t.addLabel('start');
      t.fromTo(
        maskRef.current,
        duration,
        { y: '0%' },
        { y: '-100%', ease: 'Power4.easeInOut' },
        'start'
      );
      t.fromTo(
        brandRef.current,
        duration * 0.75,
        { y: '0%' },
        { y: '-100%', ease: 'Power4.easeInOut' },
        `start+=${duration * 0.25}`
      );
      t.fromTo(logoRef.current, duration / 2, { opacity: 1 }, { opacity: 0 }, `start`);
      t.call(() => ui.togglePageTransition(false));
      t.set([maskRef.current, brandRef.current, logoRef.current], { display: 'none' });
    };

    router.events.on('routeChangeComplete', hide);
    router.events.on('routeChangeError', hide);

    router.events.on('routeChangeStart', () => NProgress.start());
    router.events.on('routeChangeComplete', () => NProgress.done());
    router.events.on('routeChangeError', () => NProgress.done());

    // set focus to logo on navigation
    router.events.on('routeChangeComplete', () => {
      const logo = document.getElementById('logo');
      if (logo) {
        // logo.focus();
      }
    });

    return () => {
      router.events.off('routeChangeComplete', hide);
      router.events.off('routeChangeError', hide);
    };
  }, [ui]);

  return (
    <div className={s.pageTransition}>
      {children}

      <div ref={logoRef} className={s.pageTransition__logo}>
        <BrandLogo />
      </div>
      <div ref={brandRef} className={s.pageTransition__brand} />
      <div ref={maskRef} className={s.pageTransition__mask} />
    </div>
  );
};
