import React from 'react';

const defaultValues = {
  isTest: false,
  backgroundSections: [],
  isNavigationOpen: false,
  isNavigationAnimating: false,
  isTopNavigationOpen: false,
  isPageTransitioning: false,
  toggleTest: (active?: boolean | undefined) => {},
  toggleNavigation: (open?: boolean | undefined) => {},
  toggleNavigationAnimating: (open?: boolean | undefined) => {},
  toggleTopNavigation: (open?: boolean | undefined) => {},
  togglePageTransition: (isTransitioning?: boolean | undefined) => {},
  getCurrentSection: (position: number) => ({
    id: '',
    color: '',
    top: 0,
    bottom: 100,
  }),
  registerSection: (sectionRef: any, reusableId: string) => {},
  unregisterSection: (sectionRef: string) => {},
};
export const UIContext = React.createContext(defaultValues);

type ProviderProps = {
  children: React.ReactNode;
};

export const UIProvider = ({ children }: ProviderProps) => {
  const [isTest, setTest] = React.useState(false);
  const [isNavigationOpen, setNavigationOpen] = React.useState(false);
  const [isNavigationAnimating, setNavigationAnimating] = React.useState(false);
  const [isTopNavigationOpen, setTopNavigationOpen] = React.useState(false);
  const [isPageTransitioning, setPageTransitioning] = React.useState(false);

  const toggleTest = (active: boolean | undefined) => {
    setTest(active === undefined ? !isTest : active);
  };

  const toggleNavigation = (open: boolean | undefined) => {
    setNavigationOpen(open === undefined ? !isNavigationOpen : open);
  };

  const toggleNavigationAnimating = (open: boolean | undefined) => {
    setNavigationAnimating(open === undefined ? !isNavigationAnimating : open);
  };

  const toggleTopNavigation = (open: boolean | undefined) => {
    setTopNavigationOpen(open === undefined ? !isTopNavigationOpen : open);
  };

  const togglePageTransition = (isTransitioning: boolean | undefined) => {
    setPageTransitioning(isTransitioning === undefined ? !isPageTransitioning : isTransitioning);
  };

  const [backgroundSections, setBackgroundSections] = React.useState<
    Array<{ id: string; color: string; top: number; bottom: number }>
  >([]);

  const getCurrentSection = (scrollY) => {
    if (backgroundSections.length === 0) {
      return undefined;
    }

    const offset = window.innerHeight / 2;

    return backgroundSections.reduce((prev, curr: any) => {
      if (scrollY + offset > curr.top && scrollY - offset / 2 < curr.bottom) {
        return curr;
      }

      return prev;
    }, undefined);
  };

  const registerSection = (
    section: { color: string; top: number; bottom: number },
    reusableId: string
  ) => {
    setBackgroundSections((prevSections) => {
      const item = prevSections.find((section) => section.id === reusableId);
      if (item) {
        return prevSections.map((section) =>
          section.id === reusableId ? { ...section, ...section } : section
        );
      } else {
        return [...prevSections, { ...section, id: reusableId }];
      }
    });
  };

  const unregisterSection = (sectionRef: string) => {
    setBackgroundSections((prevSections) => prevSections.filter((bs) => bs.id !== sectionRef));
  };

  return (
    <UIContext.Provider
      value={{
        ...defaultValues,
        backgroundSections,
        isTest,
        isNavigationOpen,
        isNavigationAnimating,
        isTopNavigationOpen,
        isPageTransitioning,
        toggleTest,
        toggleNavigation,
        toggleNavigationAnimating,
        toggleTopNavigation,
        togglePageTransition,
        getCurrentSection,
        registerSection,
        unregisterSection,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUI = () => React.useContext(UIContext);
