import getConfig from 'next/config';
const { publicRuntimeConfig = {} }: any = getConfig() || {};

const isDev =
  process.env.CURRENT_ENV !== 'production' &&
  process.env.NODE_ENV !== 'production' &&
  publicRuntimeConfig.NODE_ENV !== 'production';
const languages = process.env.LANGUAGES && process.env.LANGUAGES.split(',');
const prismicLanguages = process.env.LANGUAGES && process.env.PRISMIC_LANGUAGES.split(',');

export const config = {
  theme: process.env.THEME || publicRuntimeConfig.THEME || 'no-theme',
  mapKey:
    process.env.MAP_KEY || publicRuntimeConfig.MAP_KEY || 'AIzaSyC3-e_-xKLatSxhqEJfzb6ZisQ5Fqxul1o',
  loadDevTools: isDev,
  prismic: {
    repository: process.env.PRISMIC_REPO || 'alvogen',
    apiKey:
      process.env.PRISMIC_API_KEY ||
      'MC5ZTXBEVWhBQUFDTUFnb3g4.Pe-_ve-_vRbvv70F77-977-977-9TS_vv73vv716Xe-_vQbvv71M77-977-977-9dO-_ve-_vUPvv73vv73vv71K77-977-9',
    baseContentType: process.env.BASE_CONTENT_TYPE || 'home',
    apiUrl:
      process.env.PRISMIC_API_URL ||
      publicRuntimeConfig.PRISMIC_API_URL ||
      'https://alvogen-dev.cdn.prismic.io/api/v2',
    release: process.env.PRISMIC_RELEASE || null,
  },
  i18n: {
    default: process.env.DEFAULT_LANGUAGE || publicRuntimeConfig.DEFAULT_LANGUAGE || 'en',
    current: '',
    languages: languages || ['en'],
    prismic: prismicLanguages || ['en-us'],
    displayMap: {
      en: 'English',
      zh: 'Chinese',
      ja: 'Japanese',
    },
  },
  animations: {
    pageTransition: {
      showDuration: 1000,
      hideDuration: 1000,
    },
  },
  newsletter:
    process.env.NEWSLETTER ||
    'https://alvogen.us7.list-manage.com/subscribe/post?u=54215ccf29ae30e300f442fbf&amp;id=53967b694b',
  contactFormUrl: '',
  gtmId: process.env.GTM_ID || publicRuntimeConfig.GTM_ID,
  captchaSiteKey: publicRuntimeConfig.CAPTCHA_SITE_KEY || process.env.CAPTCHA_SITE_KEY,
  cookiebotId: process.env.COOKIEBOT_ID || publicRuntimeConfig.COOKIEBOT_ID,
  typographyId: process.env.TYPOGRAPHY_ID,
  ist85certification: (process.env.IST85_CERTIFICATION || '') === 'true',

  revalidate: process.env.NEXT_REVALIDATE_DEFAULT
    ? parseInt(process.env.NEXT_REVALIDATE_DEFAULT, 10)
    : 10,

  /**
   * Some sites use a normal page for the newsroom, this is how we disable the normal newsroom page
   */
  newsroomDisabled: (process.env.NEWSROOM_DISABLED || '') === 'true',

  // warning for external links
  externalLinkWarning: (process.env.EXTERNAL_LINK_WARNING || '') === 'true',

  isDev,
};

// console.log(process.env, publicRuntimeConfig, config);
