import React from 'react';
import { config } from 'utils/config';

import Alvogen from './alvogen.svg';
import Alvotech from './alvotech.svg';
import Lotus from './lotus.svg';
import Adalvo from './adalvo.svg';
import Almatica from './almatica.svg';
import Almaject from './almaject.svg';

export const BrandLogo =
  {
    alvotech: Alvotech,
    lotus: Lotus,
    adalvo: Adalvo,
    almatica: Almatica,
    almaject: Almaject,
  }[config.theme] || Alvogen;
